<template>
    <div class="pageContainer targetPage flexCloumn">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1">
                组织架构：
                <tchtree v-model="queryForm.class_id" widthVal="width:400px;"></tchtree>

                <span v-if="jieciList.length > 0">届次：</span>
                <el-select v-if="jieciList.length > 0" v-model="queryForm.jieci" size="small">
                    <el-option label="全部" value="item"></el-option>
                    <el-option v-for="(item, i) in jieciList" :key="i" :label="item" :value="item"></el-option>
                </el-select>

                <el-button @click="getList" icon="el-icon-search" type="primary" size="small"
                    style="margin-left:10px">查询</el-button>
            </div>

            <div class="flexStart"
                style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">
                <el-button @click="exportData" type="text" icon="el-icon-download" size="small">导出当前表格</el-button>
            </div>


        </el-row>
        <div class="dividerBar"></div>

        <el-table v-tableFit :data="DataList" row-key="id" height="100%" size="small" stripe border
            style="width:fit-content;width:100%;">
            <el-table-column type="index" label="序号" width="65" align="center" />
            <el-table-column prop="loginname" label="工号" width="135" align="center" />
            <el-table-column prop="teacher_name" label="教师姓名" width="135" align="center" />
            <el-table-column prop="sx_plan" label="实习计划" min-width="150" align="center" />
            <el-table-column prop="xf_num" label="巡访记录条数" min-width="150" align="center" />
            <el-table-column prop="good_num" label="优秀毕业生" min-width="150" align="center" />
            <el-table-column prop="sx_case" label="实习案例" min-width="150" align="center" />
            <el-table-column prop="sx_summary" label="实习总结" min-width="150" align="center" />
        </el-table>

        <el-pagination :total="page.count" layout="total" style="margin-top: 10px;">
        </el-pagination>

    </div>
</template>
  
<script>
import util from "../../utils/util";
import tchtree from '../com/tchTree.vue'
export default {
    components: {
        tchtree
    },
    data() {
        return {
            dialogShow: true,
            queryForm: {
                class_id: "",
            },
            page: {
                current_page: 1,
                next_page: 2,
                pre_page: 100,
                per_page: 100,
                total_page: 0,
                count: 0
            },
            DataList: [],
            ckdTreeData: [],
            columnList: [],
            jxConfig: {},//绩效考核配置，绩效分数为0时，不显示此列
            jieciList: [],
            ExportList: [],
            exportFields: [
                { name: '序号', field: 'idx', value: true, disabled: false, },
                { name: '实习教师姓名', field: 'teacher_name', value: true, disabled: true, },
                { name: '实习计划', field: 'sx_plan', value: true, disabled: false, },
                { name: '巡访记录条数', field: 'xf_num', value: true, disabled: false, },
                { name: '优秀毕业生', field: 'good_num', value: true, disabled: false, },
                { name: '实习案例', field: 'sx_case', value: true, disabled: false, },
                { name: '实习总结', field: 'sx_summary', value: true, disabled: false, },
            ],
            uid: ''
        };
    },
    mounted() {
        this.$http.post("/api/get_teacher_info").then(res => {
            this.uid = res.data.id
            this.getList()

        })
    },
    methods: {
        getList(uid) {
            this.$http.post("/api/teacher_sx_complete_result", { uid: this.uid, class_ids: this.queryForm.class_id, }).then(res => {
                if (res.data && res.data.length > 0) {
                    res.data.map((a, ia) => {
                        a.idx = ia + 1
                        a.sx_plan = a.sx_plan ? '完成' : '-'
                        a.xf_num = a.xf_num || 0
                        a.good_num = a.good_num || 0
                        a.sx_case = a.sx_case ? '完成' : '-'
                        a.sx_summary = a.sx_summary ? '完成' : '-'
                    })
                    this.DataList = res.data
                    this.ExportList = res.data
                    this.page.count = res.data.length
                } else {
                    this.DataList = [];
                    this.ExportList = []
                    this.page.count = 0
                }

            });
        },
        // 导出
        exportData() {
            require.ensure([], () => {
                const { export_json_to_excel } = require('../../../public/js/Export2Excel.js')
                let formatJson = (filterVal, jsonData) => {
                    return jsonData.map(v => filterVal.map(j => v[j]))
                }
                const list = this.ExportList
                let tHeader = [], filterVal = []
                this.exportFields.map(a => {
                    if (a.value) {
                        tHeader.push(a.name)
                        filterVal.push(a.field)
                    }
                })
                const data = formatJson(filterVal, list)
                let fileName = `实习带队教师情况统计表`
                export_json_to_excel(tHeader, data, fileName)
            })
        },

    }
};
</script>
  
<style lang="less">
.sumaryStyle td .cell {
    color: #2469f3;
    font-weight: 700
}

/*.sumaryStyle td:nth-of-type(2) .cell{
 color: #f56c6c;
}*/
</style>